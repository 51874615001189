import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ColorfulDvdr from './colorfulDvdr'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: `white`,
    paddingTop: `50px`,
    paddingBottom: `50px`
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  addressCard: {
    display: `flex`,
    border: `3px solid white`,
    color: `white`,
  },
  addressCardWrapper: {
    display: `flex`,
    justifyContent: `center`
  },
  map: {
    width: `100%`,
    height: `100%`,
    border: `0`
  },
  address: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`
  },
  footer: {
    width: `100%`, 
    textAlign: `center`, 
    fontFamily: `Avenir`, 
    backgroundColor: `rgb(16,16,17)`, 
    position: `absolute`,
    bottom: 0
  },
  listRoot: {
    width: '100%',
    color: `white`,
    textAlign: `center`
  },
  listItem: {
    textAlign: `center`
  },
  colorfulBottom: {
    position: `absolute`,
    bottom: 0,
    width: `100%`,
  }
}));

function ListItemLink(props) {
  const classes = useStyles();

  return <ListItem button component="a" {...props} className={classes.listItem} />;
}

const Footer = () => {
  const classes = useStyles();

  return (
    <>
    <footer className={classes.footer}>
      <div>
      <ColorfulDvdr />
      <Container>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item md={4} sm={4} xs={12}>
              <div className={classes.listRoot}>
                <List component="nav" aria-label="main">
                  <ListItemLink href="/">
                    <ListItemText primary="LUX PERFORMING ARTS" />
                  </ListItemLink>
                  {/* <ListItemLink href="/classesAtLux">
                    <ListItemText primary="Classes at LUX" />
                  </ListItemLink> */}
                  {/* <ListItemLink href="/events">
                    <ListItemText primary="Events" />
                  </ListItemLink> */}
                  <ListItemLink href="/latestAtLux">
                    <ListItemText primary="Latest at LUX" />
                  </ListItemLink>
                  {/* <ListItemLink href="/about">
                    <ListItemText primary="About" />
                  </ListItemLink> */}
                </List>
              </div>
            </Grid>
            <Grid item md={8} sm={8} xs={12} className={classes.addressCardWrapper}>
              <div className={classes.addressCard}>
                <Grid container spacing={0}>
                  <Grid item xs={6} className={classes.address}>
                    <Typography>83 Wayne St</Typography>
                    <Typography>Jersey City, NJ 07302</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <iframe
                      title={`map`}
                      className={classes.map}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.9347720674523!2d-74.0461438!3d40.71945240000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c250b15fa864a1%3A0x8cc38ec923717ff0!2s83%20Wayne%20St%2C%20Jersey%20City%2C%20NJ%2007302!5e0!3m2!1sen!2sus!4v1676744892110!5m2!1sen!2sus"
                      frameBorder="0"
                      aria-hidden="false">
                    </iframe>
                  </Grid>
                </Grid>  
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                © {new Date().getFullYear()} LUX PERFORMING ARTS
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
      </div>
      <ColorfulDvdr className={classes.colorfulBottom} />
    </footer>
    </>
  );
}

export default Footer
