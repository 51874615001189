import { makeStyles } from '@material-ui/core/styles'
import React from "react"
import clsx from 'clsx'

const useStyles = makeStyles({
  dvdrWrapper: {
    width: `100%`
  },
  dvdr: {
    height: `15px`
  },
  dvdrTop: {
    backgroundColor: `rgb(229,51,59)`,
    height: `5px`
  },
  dvdrMiddle: {
    backgroundColor: `rgb(233,114,199)`,
    height: `5px`
  },
  dvdrBottom: {
    backgroundColor: `rgb(246,139,14)`,
    height: `5px`
  }
});

const ColorfulDvdr = () => {
  const classes = useStyles();
  return (
    <div className={classes.dvdrWrapper}>
      <div className={clsx(classes.dvdr, classes.dvdrTop)}></div>
      <div className={clsx(classes.dvdr, classes.dvdrMiddle)}></div>
      <div className={clsx(classes.dvdr, classes.dvdrBottom)}></div>
    </div>
  )
}

export default ColorfulDvdr