import React from "react";
import { Modal } from "@material-ui/core";
import Typography from '@material-ui/core/Typography'
import { Facebook, Instagram } from 'react-feather';
import Image from '../image';
import './noticeModal.scss';

const NoticeModal = () => {
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <aside class="modal">
       <Image />
       <div>
         <Typography variant='h5' component='h1' gutterBottom align="center">
           Welcome to LUX Performing Arts,<br />
           home of Singing with Molly.
         </Typography>
         <Typography variant='h6' component='h2' align="center" gutterBottom>
           While the website is under construction, please find us on <a href="https://www.instagram.com/lux_performingarts/" target="_blank">Instagram</a>, <a href="https://www.facebook.com/LUXPerformingArts/" target="_blank">Facebook</a> or email: <a href="mailto:molly@luxperformingarts.com">molly@luxperformingarts.com</a>  
         </Typography>
         <Typography variant='h6' component='h2' align='center'>
          <a href="https://www.facebook.com/LUXPerformingArts/" target="_blank">
            <Facebook stroke="black" />
          </a>
          <a href="https://www.instagram.com/lux_performingarts/" target="_blank" style={{marginLeft: '.5rem'}}>
            <Instagram stroke="black" />
          </a>
         </Typography>
         <Typography variant='h6' component='h2' align="center">
           All classes are now in Philadelphia
         </Typography>
       </div>
      </aside>
    </Modal>
  );
};

export default NoticeModal;
