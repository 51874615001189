import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let Theme = createMuiTheme({
  palette: {
    primary: {
    //  light: '#757ce8',
      main: '#fe66cb',
    //  dark: '#002884',
    //  contrastText: '#fff',
    },
    secondary: {
      light: '#fe3324',
      main: '#fe3324',
      dark: '#fe3324',
      contrastText: '#fe3324',
    },
    
  },
  typography: {
    fontFamily: 'Poppins'
  }
});

Theme = responsiveFontSizes(Theme)

export default Theme