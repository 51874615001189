import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Image from '../image'
import BurgerMenu from './burgerMenu'
import DesktopMenu from './desktopMenu'
import Hidden from '@material-ui/core/Hidden';
import AniLink from "gatsby-plugin-transition-link/AniLink"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `rgb(203, 230, 245)`,
  },
  headerImg: {
    zIndex: 99,
    maxHeight: `90px`,
    flexGrow: 0,
    paddingLeft: `20px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `0px`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    textTransform: 'none',
  },
  linksWrapper: {
    flexGrow: 1,
    display: `flex`,
    justifyContent: `flex-end`
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `8px`
    }
  }
}));

const Menu = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Toolbar className={classes.toolbar}>
            <div className={classes.headerImg}>
              <AniLink paintDrip to="/" hex="#f7cd56">
                <Image />
              </AniLink>
            </div>
            <div className={classes.linksWrapper}>
              <Hidden smDown>
                <DesktopMenu />
              </Hidden>
              <Hidden mdUp>
                <BurgerMenu />
              </Hidden>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Menu