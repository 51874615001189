import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AniLink from "gatsby-plugin-transition-link/AniLink"

import './popoutMenu.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: `#fafafa`,
  },
  menuItem: {
    textTransform: 'none',
    borderRadius: '0px',
    color: 'black',
    '&:hover, & $active': {
      backgroundColor: '#f7cd56',
      color: `#fe66cb`,
    }
  },
  icon: {
    fontSize: `45px`,
    fontWeight: `100`,
  },
  navWrapper: {
    width: `20%`,
    display: `flex`,
    justifyContent: `space-around`
  },
  active: {
    backgroundColor: '#f7cd56',
    display: 'flex'
  }
}));

const DesktopMenu = () => {
  const classes = useStyles();

  return (
    <div className={classes.navWrapper}>
      {/* <AniLink paintDrip to="/classesAtLux" hex="#f7cd56" activeClassName={classes.active}>
        <Button className={classes.menuItem}>classes at LUX</Button>
      </AniLink> */}
      {/* <a href={`https://luxperformingarts.myshopify.com/collections/broadway`} target='_blank' rel='noreferrer'>
        <Button className={classes.menuItem}>classes at LUX</Button>
      </a> */}
      {/* <div className="reference"> */}
      {/* <AniLink paintDrip to="/events" hex="#f7cd56" activeClassName={classes.active}>
        <Button className={classes.menuItem}>events</Button>
      </AniLink> */}
        {/* <div className="popout-menu">
          <AniLink paintDrip to="/showtime" hex="#f7cd56" activeClassName={classes.active}>
            <Button className={classes.menuItem}>showtime</Button>
          </AniLink>
        </div>
      </div> */}
      <AniLink paintDrip to="/" hex="#f7cd56" activeClassName={classes.active}>
        <Button className={classes.menuItem}>home</Button>
      </AniLink>
      <AniLink paintDrip to="/latestAtLux" hex="#f7cd56" activeClassName={classes.active}>
        <Button className={classes.menuItem}>latest at LUX</Button>
      </AniLink>
      {/* <AniLink paintDrip to="/about" hex="#f7cd56" activeClassName={classes.active}>
        <Button className={classes.menuItem}>about</Button>
      </AniLink> */}
      {/* <a href={`https://fundraising.fracturedatlas.org/lux-performing-arts`} target='_blank' rel='noreferrer'>
        <Button className={classes.menuItem}>donate</Button>
      </a> */}
    </div>
  )
}

export default DesktopMenu