/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Theme from './theme'
import Menu from './header/menu'
import NoticeModal from './noticeModal/noticeModal'
import Footer from './footer'
import './layout.scss'

const Layout = ({ children }) => {
  useEffect(() => {
    window.location.replace("https://beacons.ai/luxperformingarts");
  });
  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={Theme}>
        <div className="main-container">
          <NoticeModal />
          {/* <Menu /> */}
          {/* <main>{children}</main> */}
          {/* <Footer /> */}
        </div>
      </MuiThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
