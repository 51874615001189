import React, {useState} from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import './burgerMenu.scss'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menu: {
    width: `100%`,
    backgroundColor: `#f7cd56`,
    transition: `none`,
    display: `flex`,
    justifyContent: `center`
  },
  linkText: {
    color: `#fe66cb`,
    fontSize: `2rem`,
    fontWeight: `900`,
    textAlign: `center`,
  },
  active: {
    backgroundColor: 'black',
  }
});

export default function BurgerMenu() {
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'left' || anchor === 'right',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <AniLink paintDrip to="/" hex="#f7cd56">
            <ListItem className={classes.blogItem} key={`home`}>
              <ListItemText primary={`home`} classes={{primary: classes.linkText}}  />
            </ListItem>
          </AniLink>
          {/* <AniLink paintDrip to="" hex="#f7cd56">
            <ListItem className={classes.blogItem} key={`school`}>
              <ListItemText primary={`classes at LUX`} classes={{primary: classes.linkText}}  />
            </ListItem>
          </AniLink> */}
          {/* <a href={`https://luxperformingarts.myshopify.com/collections/broadway`} target='_blank' rel='noreferrer'>
            <ListItem className={classes.blogItem} key={`donate`}>
                <ListItemText primary={`classes at LUX`} classes={{primary: classes.linkText}} />
            </ListItem>
          </a> */}
          {/* <AniLink paintDrip to="/events" hex="#f7cd56">
            <ListItem className={classes.blogItem} key={`events`}>
              <ListItemText primary={`events`} classes={{primary: classes.linkText}}  />
            </ListItem>
          </AniLink> */}
          {/* <AniLink paintDrip to="/showtime" hex="#f7cd56">
            <ListItem className={classes.blogItem} key={`showtime`}>
              <ListItemText primary={`showtime`} classes={{primary: classes.linkText}}  />
            </ListItem>
          </AniLink> */}
          <AniLink paintDrip to="/latestAtLux" hex="#f7cd56">
            <ListItem className={classes.blogItem} key={`latestAtLux`}>
              <ListItemText primary={`latest at LUX`} classes={{primary: classes.linkText}}  />
            </ListItem>
          </AniLink>
          {/* <AniLink paintDrip to="/about" hex="#f7cd56">
            <ListItem className={classes.blogItem} key={`about`}>
              <ListItemText primary={`about`} classes={{primary: classes.linkText}}  />
            </ListItem>
          </AniLink>
          <a href={`https://fundraising.fracturedatlas.org/lux-performing-arts`} target='_blank' rel='noreferrer'>
            <ListItem className={classes.blogItem} key={`donate`}>
                <ListItemText primary={`donate`} classes={{primary: classes.linkText}} />
            </ListItem>
          </a> */}
      </List>
    </div>
  );

  return (
    <React.Fragment key={'right'}>
      <button 
        id="burger-button"
        onClick={toggleDrawer('right', !state.right)}
        className={state.right === true ? "target-burger toggled" : "target-burger"} >
        <ul className="buns">
          <li className="bun"></li>
          <li className="bun"></li>
        </ul>
      </button>
      <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)} classes={{paperAnchorRight: classes.menu}}>
        {list('right')}
      </Drawer>
    </React.Fragment>
  );
}